<template>
    <a-button 
        v-if="getRouteInfo && getRouteInfo.pageActions && getRouteInfo.pageActions.add"
        type="primary" 
        icon="plus"
        size="large"
        @click="$store.commit('meeting/SET_EDIT_DRAWER', { show: true, model: model })">
        Создать конференцию
    </a-button>
</template>

<script>
export default {
    name: "MeetingAddButton",
    props: {
        model: {
            type: String,
            default: 'main'
        },
        getRouteInfo: {
            type: Object,
            default: () => null
        }
    }
}
</script>